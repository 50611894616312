import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NavigationStart, Router} from "@angular/router";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('hideShow', [
      state('show', style({
        maxHeight: '1000px',
        zIndex: '200'
      })),
      state('hide', style({
        maxHeight: '0px'
      })),
      transition('hide => show', [
        animate('1s')
      ]),
    ])
  ]
})

export class HeaderComponent implements OnInit {
  isOpen = false;
  margin = 0;
  @ViewChild('cont') cnt: ElementRef;
  @ViewChild('nav') nav: ElementRef;


  constructor(private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe(x => {
      if ('navigationTrigger' in x) {
        this.toggleMenu(false);
      }
    });
    this.onResize();
  }

  toggleMenu(x = null) {
    if (x === null) {
      this.isOpen = !this.isOpen;
    } else {
      this.isOpen = !!x;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const width = this.nav.nativeElement.clientWidth;
    if (width < 960) {
      this.margin = 0;
      return;
    }
    this.margin = Math.floor((width - this.cnt.nativeElement.clientWidth) / 2);
  }

}
