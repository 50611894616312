import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MainComponent} from './site/main/main.component';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './layout/header/header.component';
import {AppCommonModule} from './layout/app-common.module';
import {NgxImageGalleryModule} from "ngx-image-gallery";
import {AgmCoreModule} from "@agm/core";


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    NgbModule,
    AppCommonModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
