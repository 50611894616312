import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {MainComponent} from "./site/main/main.component";


const routes: Routes = [
  {path: '', component: MainComponent},
  {path: '', loadChildren: './site/site.module#SiteModule'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes), NgbModule],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
